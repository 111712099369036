* {
  margin: 0% !important;
  font-family: "Questrial", sans-serif;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.cardTask {
  height: auto !important;
  width: 650px !important;
  text-align: center;
  color: #006279;
  border-radius: 10px;
  border: 1px solid #ccc;
  background: #fff;
  float: left;
  padding: 0%;
  font-size: 20px;
  transition: box-shadow 0.3s;
  margin-left: 20px !important;
}
.containerModules {
  justify-content: space-evenly;
}
.cardTask:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.dateTask {
  width: 100%;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  /*estilo para las fechas*/

  justify-content: space-between;
  font-size: 15px;
  padding: 5px;
  color: rgb(107, 107, 107);
}

.actionTask {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.btnEliminar {
  background-color: bisque;
}

.botonChido {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  color: #fff !important;
  width: 200px;
  height: 50px;
  position: relative;
  justify-content: center !important;
  text-decoration: none;
  background: none;
  border: 3px solid white;
  border-radius: 50px;
  transition: all 0.5s ease 0s;
  font-size: 20px;
}
.botonChido:hover {
  width: 250px;
  padding-top: 10px;
  height: 55px;
  background-color: 0.5s ease;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.23853291316526615) 0%,
    rgba(255, 255, 255, 0.12088585434173671) 100%
  );
  font-size: 22px;
  color: #fff !important;
  border: none;
  transition: all 0.5s ease-out 0s;
}

.landPage {
  background: rgb(74, 110, 144);
  background: linear-gradient(
    180deg,
    rgba(74, 110, 144, 1) 0%,
    rgba(17, 27, 68, 1) 100%
  );
  border-radius: 0% !important;
}

.botonLlamar {
  color: #fff !important;
  width: 220px;
  height: 50px;
  justify-content: center !important;
  text-decoration: none;
  background: #00608e;

  -webkit-box-shadow: 0px 5px 28px 5px rgba(0, 96, 142, 0.25);
  box-shadow: 0px 5px 28px 5px rgba(0, 96, 142, 0.25);

  border-radius: 5px;
  transition: all 0.5s ease 0s;
  font-size: 17px;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
}

@media only screen and (max-width: 570px) {
  .cardTask {
    height: auto !important;
    width: 300px;
    text-align: center;
    color: #006279;
    border-radius: 10px;
    border: 1px solid #ccc;
    background: #fff;
    float: left;
    transition: box-shadow 0.3s;
  }
}

.titleTask {
  font-size: 8vh;
}

.headerLens {
  margin: 0px;
  width: 100%;
  background: rgba(0, 56, 79, 1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
}

.heroPage {
  padding: 0%;
  border-radius: 0%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0% !important;
  text-align: center;
  height: 500px !important;
  background: rgb(0, 36, 51);
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      325deg,
      rgba(0, 36, 51, 1) 0%,
      rgba(75, 112, 149, 0.5683648459383753) 100%
    ),
    url("assets/images/cloudsSky.jpg");
  background-repeat: no-repeat;
}

.logoIDI {
  margin: 0% !important;
  width: 30%;
}
@media only screen and (max-width: 570px) {
  .logoIDI {
    width: 70%;
  }
}

.contentMain {
  background: rgb(0, 36, 51);
  background: linear-gradient(
    4deg,
    rgba(0, 36, 51, 1) 0%,
    rgba(75, 112, 149, 1) 100%
  );
}

/*Home page*/

.onlineVideo {
  border-radius: 15px;
  -webkit-box-shadow: 0px 40px 60px 0px rgba(141, 139, 139, 0.2);
  -moz-box-shadow: 0px 40px 60px 0px rgba(141, 139, 139, 0.2);
  box-shadow: 0px 40px 60px 0px rgba(141, 139, 139, 0.2);

  height: 400px;
}

@media only screen and (max-width: 570px) {
  .onlineVideo {
    width: 300px;
    height: 300px;
  }
}

.titleApp {
  font-size: 30px !important;
  color: "#fff";
}

.custom-btn {
  padding: 10px 25px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  color: #fff;
  font-size: 30px;
}

/**** BTN No. 4 ****/
.btn-4 {
  width: 300px;
  height: 50px;
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-4 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-4:before,
.btn-4:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: linear-gradient(
    54deg,
    rgba(255, 153, 0, 1) 0%,
    rgba(255, 219, 165, 1) 100%
  );
  transition: all 0.3s ease;
}
.btn-4:before {
  height: 70%;
  width: 5px;
}
.btn-4:after {
  width: 50%;
  height: 5px;
}
.btn-4:hover:before {
  height: 100%;
}
.btn-4:hover:after {
  width: 100%;
}
.btn-4 span:before,
.btn-4 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: linear-gradient(
    54deg,
    rgba(255, 153, 0, 1) 0%,
    rgba(255, 219, 165, 1) 100%
  );
  transition: all 0.3s ease;
}
.btn-4 span:before {
  width: 5px;
  height: 50%;
}
.btn-4 span:after {
  width: 50%;
  height: 5px;
}
.btn-4 span:hover:before {
  height: 100%;
}
.btn-4 span:hover:after {
  width: 100%;
}

.btn-5 {
  width: 300px;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-5 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-5:before,
.btn-5:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: linear-gradient(
    54deg,
    rgba(255, 153, 0, 1) 0%,
    rgba(255, 219, 165, 1) 100%
  );
  transition: all 0.3s ease;
}
.btn-5:before {
  height: 70%;
  width: 5px;
}
.btn-5:after {
  width: 50%;
  height: 5px;
}
.btn-5:hover:before {
  height: 100%;
}
.btn-5:hover:after {
  width: 100%;
}
.btn-5 span:before,
.btn-5 span:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: linear-gradient(
    54deg,
    rgba(255, 153, 0, 1) 0%,
    rgba(255, 219, 165, 1) 100%
  );
  transition: all 0.3s ease;
}
.btn-5 span:before {
  width: 5px;
  height: 50%;
}
.btn-5 span:after {
  width: 70%;
  height: 5px;
}
.btn-5 span:hover:before {
  height: 100%;
}
.btn-5 span:hover:after {
  width: 100%;
}

.gruopButons {
  margin-top: 20px !important;
}

.botonDecision {
  border: 1px solid #979797;
  border-radius: 10px;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px !important;
  background-color: #fff;
  width: 200px;
  height: 50px;

  font-size: 22px;
}

.botonDecision:hover {
  background: #d4f1ff;
  transition: all 0.1s ease-out 0s;
}

.iconoIDI {
  margin: 20px !important;
  height: 110px;
}

.containerCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.toCenter {
  text-align: center;
}

.borderRed {
  text-align: center;
  display: flex;
  justify-content: center;
}

.divider {
  border: 1px solid red;
  display: block !important;
}

.contactCard {
  /* Rectangle 4 */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  height: 270px;
  width: 350px;
  background-size: 150%;
  /*background-image: url("assets/images/undraw_work_chat_erdt.png");*/
  background-position: center !important;
  background-repeat: no-repeat;
  -webkit-box-shadow: 0px 40px 60px 0px rgba(141, 139, 139, 0.2);
  -moz-box-shadow: 0px 40px 60px 0px rgba(141, 139, 139, 0.2);
  box-shadow: 0px 40px 60px 0px rgba(141, 139, 139, 0.2);
}

.centerContet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputData {
  text-align: left !important;
}

.contactUs {
  font-weight: bold;
  background: linear-gradient(to left, #45bcf6 0%, #00608e 100%);
  -webkit-background-clip: text;
  margin-bottom: 10px !important;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
}

.botonYoutube {
  margin: 10px !important;

  border: 5px solid transparent;
  padding: 0.5rem;
  border-radius: 50px !important;
  color: #fff;
  border-image-source: linear-gradient(
    325deg,
    rgba(208, 143, 2, 1) 0%,
    rgba(255, 224, 56, 1) 50%,
    rgba(147, 128, 0, 1) 100%
  );
  border-image-slice: 5;

  border-radius: 15px;
  background-color: transparent !important;
  margin: 10px !important;
  color: #fff !important;
  height: auto;
}

.botonYoutube > img {
  height: 35px;
  margin-right: 5px !important;
}

.botonFacebook {
  margin: 10px !important;

  border: 5px solid transparent;
  padding: 0.5rem;
  border-radius: 50px !important;
  color: #fff;
  border-image-source: linear-gradient(
    325deg,
    rgba(208, 143, 2, 1) 0%,
    rgba(255, 224, 56, 1) 50%,
    rgba(147, 128, 0, 1) 100%
  );
  border-image-slice: 5;

  border-radius: 15px;
  background: transparent;
}
.botonFacebook > img {
  height: 35px;
  margin-right: 5px !important;
}

.titleApp {
  font-size: 60px;
  color: #fff;
}

.iconoChat {
  height: 130px;
}

.subHero {
  padding: 0%;
  padding-top: 5%;
  border-radius: 0%;
  text-align: center;
  height: 400px !important;
  background-color: #00608e;

  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      180deg,
      rgba(0, 180, 220, 1) 0%,
      rgba(36, 117, 135, 0.30575980392156865) 100%
    ),
    url("assets/images/optica.jpg");
  background-repeat: no-repeat;
}

@media only screen and (max-width: 570px) {
  .subHero {
    height: 500px !important;
  }
}
.suber {
  margin-top: 80px !important;
}

.centerText {
  text-align: center;
  color: #fff;
  font-size: 100px;
  font-weight: 600;
}

.citasHorarios {
  text-align: center;
  color: #fff;
  font-size: 100px;
}

@media only screen and (max-width: 570px) {
  .citasHorarios {
    text-align: center;
    color: #fff;
    font-size: 60px !important;
  }
}
.dataContact {
  text-align: left;
  font-size: 25px;
  color: #fff;
  margin: 50px !important;
}
.ubicacionMapa {
  width: 500px;
  height: 350px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 10px 43px -16px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 43px -16px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 43px -16px rgba(0, 0, 0, 0.75);
}

.horario {
  margin-top: 30px !important;
  font-size: 30px;
  color: #fff;
  font-weight: 600;
}

.locationSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (max-width: 570px) {
  .horario {
    font-size: 25px;
  }
  .locationSection {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 570px) {
  .ubicacionMapa {
    width: 340px;
    border: 3px solid #dedede;
    height: 340px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 10px 26px -23px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 10px 26px -23px rgba(0, 0, 0, 1);
    box-shadow: 0px 10px 26px -23px rgba(0, 0, 0, 1);
  }

  .callSection {
    border: 1px solid red;
    height: 100px !important;
    margin-bottom: 20px !important;
  }

  .bestLenSale {
    font-size: 40px !important;
  }
}

.qualityText {
  text-align: center;
  color: #fff;
  margin-top: 20px !important;
  font-size: 50px;
}

.bestLenSale {
  margin-top: 60px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  text-align: center;
  color: #fff;
  font-size: 45px;
  margin-bottom: 60px !important;
}

.excelencia {
  margin-top: 60px !important;
  text-align: center;
  color: #4a4a4a;
  font-size: 50px;
  margin-bottom: 60px !important;
}

.lensOfferted {
  margin: 10px !important;
  font-size: 25px;
  color: #525252;
  text-align: left;
  font-weight: 700;
}

.centerContent {
  text-align: center !important;
  justify-content: center !important;
  height: 548px;
}

.centerContentLanding {
  text-align: center !important;
  justify-content: center !important;
}

.iconImages {
  align-self: center;
  width: 40% !important;
}
.checkImage {
  padding-top: 10px !important;
  align-self: center;
  margin-bottom: 20px !important;
  height: 150px;
}

.cardItemsHome {
  border-radius: 20px;
  padding: 0%;
  padding-top: 10px;
  align-items: center;
  margin: 20px !important;
}

.pInformatives {
  color: #fff;
  font-size: 30px;
  margin-bottom: 10px !important;
}

.directionP {
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px !important;
}

@media only screen and (max-width: 600px) {
  .centerText {
    text-align: center;
    color: #fff;
    font-size: 60px;
    font-weight: 600;
  }
  .pInformatives {
    color: #fff;
    font-size: 22px !important;
    margin-bottom: 10px;
  }
  .directionP {
    color: #fff;
    font-size: 10px "";
  }
}

/* Step 1, select lens or glasses */

.descriptionLens {
  font-size: 15px !important;
  margin: 20px !important;
  text-align: left !important;
  color: #525252;
}

.titleStep {
  font-size: 40px !important;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-top: 20px !important;
  color: #45bcf6;
}

.selectLensText {
  color: #00608e;
  font-size: 30px !important;
  text-align: "center" !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.subjectToTermns {
  font-size: 15px;
  text-align: center;
  color: #525252;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

.markWeater {
  font-size: 15px;
  text-align: center !important;
  padding-top: 5px;
  color: #ffffff;
  height: 30px;
  font-weight: 650;
  background: linear-gradient(to right, #00608e 0%, #45bcf6 74%);
}

/* Graduation section */
.graduationBlockSpan {
  background-color: #00608e;
  color: #ffffff;
  font-size: 20px !important;
  font-size: 20px;
  padding-left: 5px !important;
  align-self: center !important;
  height: 35px;
  padding-right: 5px !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.graduationSelect {
  border: 0.5px solid #8c8c8c;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

  height: 35px !important;
}
.inputGraduation {
  width: 800px !important;
}
.sectionGrad {
  margin-top: 50px !important;
}

.subName {
  width: 500px;
  padding: 20px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 32px -12px rgba(112, 112, 112, 1);
  -moz-box-shadow: 0px 10px 32px -12px rgba(112, 112, 112, 1);
  box-shadow: 0px 10px 32px -12px rgba(112, 112, 112, 1);
}

.topContainer {
  display: flex;
  flex-direction: row;
}

.btnArmar2 {
  padding-top: 10px !important;
  margin: 15px !important;
}

.graduationInput {
  border: 1px solid #8c8c8c;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 140px;
}

/*Data form section*/

.inputsData {
  align-self: center;
}

.iconContactForm {
  align-self: center;
  margin-right: 5px !important;

  width: 35px;
  height: 35px;
}

/* Validators */

.invalidNumber {
  align-self: center;
  background-color: #ffc8c8;
  color: #8a0000;
  border: 1px solid #fa7575;
}

.correctNumber {
  align-self: center;
  color: #0567a0;
  background-color: #c7e6ff;
  border: 1px solid #7ecbf8;
}

.correctNumber[type="text"],
textarea {
  align-self: center;
  background-color: #b3ddff;
}

/*Confirm order section */

.headerTable {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #006279;
  background-image: linear-gradient(71deg, #0394da 0%, #42baf5 100%) !important;
}

.titleTable {
  font-size: 35px;
  color: #fff;
  font-weight: 700;
}

.helpText {
  margin-bottom: 20px !important;
  height: 90px;
}
.help {
  padding: 10px;
  color: #323232;
  font-size: 20px;
}

.orderDetailsTable {
  justify-content: left;
  text-align: left;
  padding-right: 400px !important;
  padding-left: 400px !important;
}

.tableText {
  font-size: 20px;
  margin-bottom: 10px !important;
}

.tableColsText {
  font-size: 30px;
}

.headerClientData {
  align-self: center;
  border: 0.5px solid #8c8c8c;
  margin-bottom: 20px !important;
  border-radius: 10px;
}
.titleHeaderClient {
  color: #45bcf6;
  width: auto;
  font-size: 35px;
  text-align: center;
  font-weight: 600;
}

.productDetailsTitle {
  color: #00608e;
  font-size: 20px;
}

.imgLensDetail {
  overflow: hidden;
  width: 80px;
}

.detailsLensSection {
  padding-left: 10px;
  padding-top: 5px;
  margin-bottom: 40px !important;
}

.containerImg {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border: 1px solid #8c8c8c;
  border-radius: 8px;
  padding: 5px;
  width: 90px;
  height: 90px;
}

.detailsText {
  margin-bottom: 4px !important;
  font-size: 14px;
}

/*alerts*/

.AlertError {
  width: 300px;
  margin-top: 10px !important;
  text-align: center;
  padding-top: 2px;
  justify-content: center;
  height: 30px;
  background-color: #d8fafc;
  color: #0a6e75;
  border-radius: 5px;
  transition: width 2s;
}

#areaNumber {
  z-index: 100;
  display: inline-block;
  width: auto;
}

/* Dashboard a*/

.titleSeccion {
  font-size: 30px;
  color: #000;
}

.indicatorUsername {
  color: #ffff;
  font-size: 25px;
}

.indicatorOrders {
  margin-top: 10px !important;
  font-size: 20px;
}

.OrdersIndicatorSecction {
  width: 800px;
  height: 100px;
  padding: 10px;
  padding-left: 50px !important;
  color: #fff;
  border-radius: 15px;
  background: linear-gradient(to right, #0394da 0%, #42baf5 74%);
}

.searchSection {
  width: 800px;
  height: 150px;

  padding: 30px;
  border-radius: 15px;

  box-shadow: 0px 10px 34px 9px rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 0px 10px 34px 9px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 10px 34px 9px rgba(0, 0, 0, 0.21);
}

.panel {
  margin-bottom: 30px !important;
}

.inputBuscarPedido {
  text-align: center;
  border-radius: 50px;
  border: 0.5px solid #323232;
  -webkit-box-shadow: 0px 5px 22px -2px rgba(222, 222, 222, 1);
  -moz-box-shadow: 0px 5px 22px -2px rgba(222, 222, 222, 1);
  box-shadow: 0px 5px 22px -2px rgba(222, 222, 222, 1);
}

.btnAtenderPedido {
  background-color: #006279;
  background-image: linear-gradient(71deg, #0394da 0%, #42baf5 100%) !important;
  color: #fff !important;
  width: 200px;
  height: 45px;
  text-transform: capitalize !important;
  justify-content: center !important;
  text-decoration: none;
  border-radius: 50px !important;
  transition: all 0.4s ease 0s;
  font-size: 20px !important;
}

.activitySection {
  justify-content: center !important;
  text-align: center;
}

.transmition {
  margin-bottom: 50px !important;
}

.link {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 570px) {
  .link {
    display: flex;
    flex-direction: row;

    justify-content: center !important;
  }

  .inputLink {
    width: 80% !important;
  }

  .searchSection {
    width: 300px;
    height: 250px;
    font-size: 50px !important;
  }
}

.scrollbar {
  overflow: scroll;
  width: 750px;
  height: 230px !important;
}
.navdb {
  margin: 10px !important;
}

.btnNavbar {
  margin-left: 15px !important;
  text-transform: capitalize !important;
  font-size: 20px !important;
  color: #45bcf6 !important;
}

.btnNavbar:hover {
  background: rgb(218, 218, 218) !important;
  color: #00608e !important;
}

.iconLeft {
  margin-right: 20px !important;
}

/* NAVIGATION SECTION*/

.titleNavigationSection {
  text-align: center;
  background: linear-gradient(to right, #00608e 0%, #45bcf6 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  margin-left: 15px !important;
  margin-bottom: 5px;
  display: flex;
  align-self: flex-start;
}

/* Notes section*/

.addNoteButton {
  margin-left: 10px !important;
  background-color: #006279;
  background-image: linear-gradient(71deg, #0394da 0%, #42baf5 100%) !important;
  color: #fff;
  width: 120px;
  height: 35px;
  text-transform: capitalize !important;
  justify-content: center !important;
  text-decoration: none;
  background: #ffffff;
  border-radius: 50px;
  border: none;
  transition: all 0.4s ease 0s;
  font-size: 20px !important;
}

/*CardNote */

.cardNote {
  border-radius: 10px;
  border: 0.5px solid #c0c0c0 !important;
  width: 190px;
  flex: 0 1 25%; /* explanation below */
  text-align: center;
  height: 200px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px !important;
  padding: 5px;
  font-size: 20px !important;
  box-shadow: 0px 10px 34px 9px rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 0px 10px 34px 9px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 10px 34px 9px rgba(0, 0, 0, 0.21);
}

.buttonCardNote{
  margin: 15px !important;
  padding: 5px;
  border:1px solid red;
  text-align: center;
  height: 150px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 0.5px solid #c0c0c0 !important;
}

.titleCardNote {
  font-size: 18px;
  margin: 5px;
  color: #323232;
}

.badgeCardNote {
  margin: 5px;
  border-radius: 50px;
  width: 100px;
  height: 25px;
  padding-top: 2px !important;
  text-align: center;
  color: #fff;
  font-size: 15px;
}

.cardNoteContent {
  margin-top: 15px !important;
  font-size: 15px;
  color: #323232;
}

.filterDays {
  padding-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.eyeCard {
  padding-top: 50px !important;
  text-align: center;
  border-radius: 10px;
  border: 0.5px solid #c0c0c0 !important;
  width: 300px;
  height: 200px;

  margin: 10px !important;
  -webkit-box-shadow: 0px 5px 9px -1px rgba(219, 219, 219, 1);
  -moz-box-shadow: 0px 5px 9px -1px rgba(219, 219, 219, 1);
  box-shadow: 0px 5px 9px -1px rgba(219, 219, 219, 1);
}

.services {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center !important;
}
/* for responsive */
@media only screen and (max-width: 570px) {
  .services {
    flex-direction: column;
  }
  .heroPage {
    height: 280px !important;
  }

  .containerEyes {
    flex-direction: column !important;
  }
  .help {
    text-align: center;
    align-self: flex-start;
    color: #323232;
    font-size: 13px;
  }

  .tableText {
    font-size: 16px;
    margin-bottom: 10px !important;
  }

  .contactHero {
    padding: 10px;
    height: 180px !important;
  }

  .detailsText {
    margin-bottom: 4px !important;
    font-size: 13.5px;
  }

  .headerClientData {
    align-self: center;
    border: 0.5px solid #8c8c8c;
    margin-bottom: 20px !important;
    border-radius: 10px;
  }

  .titleHeaderClient {
    color: #45bcf6;
    width: auto;
    text-align: center;
    font-weight: 600;
  }
  .headerTable {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #006279;
    width: auto;
    background-image: linear-gradient(
      71deg,
      #0394da 0%,
      #42baf5 100%
    ) !important;
  }
  .helpText {
    margin-bottom: 20px !important;
    height: 100px;
  }

  .orderDetailsTable {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  .AlertError {
    width: 300px;
    margin-top: 10px !important;
    text-align: center;
    padding-top: 2px;
    justify-content: center;
    height: 30px;
    background-color: #d8fafc;
    color: #0a6e75;
    border-radius: 5px;
    transition: width 2s;
  }

  .centerContent {
    height: 700px !important;
  }
  .subName {
    width: auto !important;
    padding: 20px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;

    -webkit-box-shadow: 0px 10px 32px -12px rgba(112, 112, 112, 1);
    -moz-box-shadow: 0px 10px 32px -12px rgba(112, 112, 112, 1);
    box-shadow: 0px 10px 32px -12px rgba(112, 112, 112, 1);
  }

  .centerText {
    text-align: center;
    color: #fff;
    font-size: 55px;
    font-weight: 600;
  }
  .pInformatives {
    color: #fff;
    font-size: 20px !important;
    margin-bottom: 10px;
  }
  .directionP {
    color: #fff;
    font-size: 8px "";
  }
  .cardTask {
    height: auto !important;
    width: 290px !important;
    text-align: center;
    float: right;
    margin-left: 20px;
    color: #006279;
    border-radius: 10px;
    border: 1px solid #ccc;
    background: #fff;
    float: left;
    transition: box-shadow 0.3s;
  }

  .botonChido {
    color: #006279 !important;
    width: 270px;
    height: 50px;
    justify-content: center !important;
    text-decoration: none;
    background: #ffffff;
    border-radius: 50px;
    display: inline-block;
    border: none;
    transition: all 0.4s ease 0s;
    font-size: 20px;
  }

  .botonChidoBlue {
    color: #006279 !important;
    width: 270px;
    height: 50px;
    justify-content: center !important;
    text-decoration: none;
    border-radius: 50px;
    color: #fff !important;
    display: inline-block;
    border: none;
    transition: all 0.4s ease 0s;
    font-size: 20px;
  }

  .selectLensText {
    color: #00608e;
    text-align: center;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}

.itemTitle {
  font-size: 30px;
  color: #42baf5;
  font-weight: 600;
}

#servicesSection {
  align-items: center;
}

.opticaSection {
  margin-top: 40px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 20px !important;
  margin-bottom: 50px !important;
}

.instructionsSection {
  margin-top: 40px !important;
}

#servicesSection > p {
  width: 80%;
  font-size: 25px !important;
  text-align: justify;
}

@media only screen and (max-width: 1000px) {
  #servicesSection {
    text-align: justify;
    border-radius: 10px;
  }

  .cardNote {
    width: 300px !important;
    flex: 0 1 25% !important; /* explanation below */
  }
}

.footerPage {
  margin-top: 50px !important;
  background: rgb(0, 36, 51);
  background: linear-gradient(
    4deg,
    rgba(0, 36, 51, 1) 0%,
    rgba(75, 112, 149, 1) 100%
  );
}

.botonChidoBlue {
  background-color: #006279;
  background-image: linear-gradient(71deg, #0394da 0%, #42baf5 100%) !important;
  color: #fff;
  width: 300px;
  height: 50px;
  justify-content: center !important;
  text-decoration: none;
  background: #ffffff;
  border-radius: 50px;
  display: inline-block;
  border: none;
  transition: all 0.4s ease 0s;
  font-size: 20px;
  margin-bottom: 20px !important;
}
.botonChidoBlue:hover {
  width: 305px;
  height: 55px;
  background-color: #006279;
  background-image: linear-gradient(71deg, #0394da 0%, #42baf5 100%) !important;
  font-size: 22px;
  color: #fff !important;
  transition: all 0.4s ease 0s;
}

.botonArmar {
  background-color: #006279;
  background-image: linear-gradient(71deg, #0394da 0%, #42baf5 100%) !important;
  color: #fff;
  width: 300px;
  height: 50px;
  justify-content: center !important;
  text-decoration: none;
  background: #ffffff;
  border-radius: 50px;
  display: inline-block;
  border: none;
  transition: all 0.4s ease 0s;
  font-size: 20px;
  margin-bottom: 20px !important;
}
.botonArmar:hover {
  background-color: #006279;
  background-image: linear-gradient(71deg, #0394da 0%, #42baf5 100%) !important;
  color: #fff !important;
  transition: all 0.4s ease 0s;
}

@media only screen and (max-width: 570px) {
  .botonArmar {
    background-color: #006279;
    background-image: linear-gradient(
      71deg,
      #0394da 0%,
      #42baf5 100%
    ) !important;
    color: #fff;
    width: 220px;
    margin-top: 20px !important;
    height: 50px;
    justify-content: center !important;
    text-decoration: none;
    background: #ffffff;
    border-radius: 50px;
    display: inline-block;
    border: none;
    transition: all 0.4s ease 0s;
    font-size: 20px;
    margin-bottom: 10px !important;
  }
  .botonArmar:hover {
    background-color: #006279;
    background-image: linear-gradient(
      71deg,
      #0394da 0%,
      #42baf5 100%
    ) !important;
    color: #fff !important;
    transition: all 0.4s ease 0s;
  }
}

.legalsLink {
  font-size: 15px;
  color: #ffffff;
  text-decoration: none !important;
}

.legalsLink:hover {
  color: #fff;
}

#linktologin {
  text-decoration: inherit;
  color: inherit;
}

.blueFooterSection {
  background-color: #006279;
  background-image: linear-gradient(71deg, #247587 0%, #247587 100%) !important;
  color: #ffffff;
  clear: both;
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
}
.footerLema {
  font-size: 50px;
  font-weight: 600;
  color: #fff;
}

.footerSubLema {
  font-size: 30px;
  color: #fff;
}

/* DESARROLLAPAG*/

/*Phone */
.contactHero {
  background-color: #fff;
  padding-top: 5px;
  height: 150px;
  width: 100%;
  align-items: center;
  text-align: center;
}

.heroContact {
  color: #247587;
  font-size: 25px;
  padding-top: 10px !important;
}

/*Email*/

.heroEmail {
  margin-left: 50px !important;
  color: #fff;
  font-size: 15px;
  padding-top: 10px !important;
}

.heroEmail > img {
  padding-bottom: 5px !important;

  width: 20px !important;
}

/*iconService*/

.iconService {
  width: 50% !important;
}

.centerProduct {
  justify-content: center;
}

.descriptionService {
  width: 550px;
  text-align: left;
}

.profesionalLicense {
  font-size: 18px;
  color: #5f5f5f;
  margin: 10px !important;
  text-align: left;
}

.jobDescription {
  font-size: 18px;
  color: #247587;
  margin: 10px !important;
  text-align: left;
}

.itemDoc {
  margin: 30px !important;
}

.iconDr {
  text-align: right;
}

/*PAAO AMO*/
.membersOf {
  width: 90% !important;
}

.consulta {
  width: 180px !important;
  height: 200px !important;
  margin: 20px !important;
  border-radius: 20px;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      180deg,
      rgba(0, 180, 220, 1) 0%,
      rgba(36, 117, 135, 0.12088585434173671) 100%
    ),
    url("assets/images/consulta.jpg") !important;
}

@media only screen and (max-width: 570px) {
  .consulta {
    width: 300px !important;
  }
}

.element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px !important;
  color: #fff;
}
.cirugia {
  width: 180px !important;
  height: 200px !important;
  margin: 20px !important;
  border-radius: 20px;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      180deg,
      rgba(0, 180, 220, 1) 0%,
      rgba(36, 117, 135, 0.12088585434173671) 100%
    ),
    url("assets/images/cirugia.jpg") !important;
}

@media only screen and (max-width: 570px) {
  .cirugia {
    width: 300px !important;
  }
}

.urgencias {
  width: 180px !important;
  height: 200px !important;
  margin: 20px !important;
  border-radius: 20px;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      180deg,
      rgba(0, 180, 220, 1) 0%,
      rgba(36, 117, 135, 0.12088585434173671) 100%
    ),
    url("assets/images/urgencias.jpg") !important;
}
@media screen and (max-width: 570px) {
  .urgencias {
    width: 300px !important;
  }

  .optica {
    width: 300px !important;
  }
}

.optica {
  width: 200px !important;
  height: 200px !important;
  border-radius: 20px;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(
      180deg,
      rgba(0, 180, 220, 1) 0%,
      rgba(36, 117, 135, 0.12088585434173671) 100%
    ),
    url("assets/images/optica.jpg") !important;
}
@media screen and (max-width: 570px) {
  .optica {
    width: 300px !important;
  }
}

.botonContainer {
  text-align: left !important;
}

.botonContratar {
  padding-top: 5px !important;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  color: #fff !important;
  width: 200px;
  height: 40px;
  justify-content: center !important;
  text-decoration: none;
  background-color: #fe042f !important;
  border-radius: 50px;

  display: inline-block;
  transition: all 0.4s ease 0s;
  font-size: 18px;
}
.botonContratar:hover {
  width: 220px;
  padding-top: 10px !important;
  border: none !important;
  height: 50px;
  background-color: #fe042f !important;
  font-size: 20px;
  color: #fff !important;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
  display: inline-block;
  transition: all 0.4s ease 0s;
}

/*Textos blancos*/

.blank-text {
  color: #fff !important;
}

.table-title {
  font-weight: 700;
  color: #00608e;
  font-size: 20px;
  margin-bottom: 20px !important;
}

div.modal{
  display:flex !important;
  justify-content: center;
  align-items: center;
}

tr{
  text-align: center;
}

.shadow-table {
  padding: 5%;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 10px 34px 9px rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 0px 10px 34px 9px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 10px 34px 9px rgba(0, 0, 0, 0.21);
}

.firma {
  font-size: 16px;
  font-weight: 700 !important;
  background: rgb(255, 0, 133);
  background: linear-gradient(
    148deg,
    rgba(255, 0, 133, 1) 0%,
    rgba(0, 255, 226, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: lighter;
}



.serviceTitle {
  font-size: 25px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 10px !important;
}


.serviceDescription {
  font-size: 15px;

  color: #fff;
}

.cardService {
  width: 300px;
  height: auto;
  background-size: cover;
  border-radius: 30px;

  display: flex;
  align-items: center;
  flex-direction: column;

  margin: 20px !important;
  padding: 20px !important;
  flex: 0 1 35%;
  background-position: center;

  background-color: #fff;
  -webkit-box-shadow: 0px 10px 40px -15px rgba(143, 143, 143, 1);
  -moz-box-shadow: 0px 10px 40px -15px rgba(143, 143, 143, 1);
  box-shadow: 0px 10px 40px -15px rgba(143, 143, 143, 1);
}

.eventContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
}


.evento{
  width: 300px !important;
  border-radius: 20px !important;
  background-color: #fff;
  margin: 20px !important;
  -webkit-box-shadow: 0px 10px 40px -15px rgba(143, 143, 143, 1);
  -moz-box-shadow: 0px 10px 40px -15px rgba(143, 143, 143, 1);
  box-shadow: 0px 10px 40px -15px rgba(143, 143, 143, 1);
}


.containerPosts{
  
  width: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap
}




/*Login*/

.loginCard {
  min-width: 280px !important;
  height: 500px;

  display: flex;
  justify-content: center;

  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 50px !important;

  border-radius: 20px;
  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}

.inicioSesionTxt {
  color: #00608e;
  font-size: 14px !important;
  text-align: "center" !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.titleStep {
  font-size: 20px !important;
  text-align: center;
  margin-bottom: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-top: 20px !important;
  color: #45bcf6;
}

.iconContactForm {
  align-self: center;
  margin-right: 5px !important;

  width: 35px;
  height: 35px;
}

.inputLogin {
  border-radius: 50px;
  background: #fbfbfb;
  border: 0.5px solid #d4d4d4;
  min-width: 250px !important;
}



/**Estilos para pagina de holocaustos **/

.contenedor {
  padding: 20px;
  min-height: 100vh;
  width: 100%;
  background: rgb(0, 36, 51);
  background: linear-gradient(
    4deg,
    rgba(0, 36, 51, 1) 0%,
    rgba(75, 112, 149, 1) 100%
  );
}

.modulo {
  border-radius: 10px;
  border: 0.5px solid #c0c0c0 !important;
  width: 190px;
  flex: 0 1 25%; /* explanation below */
  text-align: center;
  height: 200px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px !important;
  padding: 5px;
  font-size: 20px !important;
  box-shadow: 0px 10px 34px 9px rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 0px 10px 34px 9px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 10px 34px 9px rgba(0, 0, 0, 0.21);
}
.areaServicios {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center !important;
}


.botonConsultarModulo {
  color: #006279 !important;
  width: 270px !important;
  height: 50px !important;
  justify-content: center !important;
  text-decoration: none !important;
  background: #ffffff !important;
  border-radius: 50px !important;
  display: inline-block !important;
  border: none !important;
  transition: all 0.4s ease 0s !important;
  font-size: 20px !important;
}


/*Efecto cuadro con desliz dorado*/

.custom-btn {
  padding: 10px 25px;
  font-family: "Poppis", sans-serif;
  font-weight: 500;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  color: #fff;
  font-size: 30px;
}


.btn-5 {
  width: auto;
  height: auto;
  line-height: auto;
  border: none;
}
.btn-5 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-5:before,
.btn-5:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: linear-gradient(
    54deg,
    rgba(255, 153, 0, 1) 0%,
    rgba(255, 219, 165, 1) 100%
  );
  transition: all 0.3s ease;
}
.btn-5:before {
  height: 70%;
  width: 5px;
}
.btn-5:after {
  width: 50%;
  height: 5px;
}
.btn-5:hover:before {
  height: 100%;
}
.btn-5:hover:after {
  width: 100%;
}
.btn-5 span:before,
.btn-5 span:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: linear-gradient(
    54deg,
    rgba(255, 153, 0, 1) 0%,
    rgba(255, 219, 165, 1) 100%
  );
  transition: all 0.3s ease;
}
.btn-5 span:before {
  width: 5px;
  height: 50%;
}
.btn-5 span:after {
  width: 70%;
  height: 5px;
}
.btn-5 span:hover:before {
  height: 100%;
}
.btn-5 span:hover:after {
  width: 100%;
}

/*Seccion centrada*/

.seccionCentrada{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.shadow-table {
  padding: 5%;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 10px 34px 9px rgba(0, 0, 0, 0.21);
  -webkit-box-shadow: 0px 10px 34px 9px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 10px 34px 9px rgba(0, 0, 0, 0.21);
}

.table-title {
  font-weight: 700;
  color: #00608e;
  font-size: 20px;
  margin-bottom: 20px !important;
}


/**botones**/

.simpleBtn{
  height: 20px !important;
  font-size: 15px !important;
  display: flex;
  align-items: center !important;
  padding: 0% !important;
  width: 60px;
  margin: 5px !important;
}

.btnAddLeft{
  justify-content: left;
  border: 1px solid red;
}

.topTable{
  border:1px solid red
}

.custom-file-input ~ .custom-file-label::after {
  content: "Abrir";
}


/** Style for input range **/

input[type=range] {
  height: 26px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #50555C;
  background: #50555C;
  border-radius: 14px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  width: 40px;
  border-radius: 12px;
  background: #529DE1;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #50555C;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #50555C;
  background: #50555C;
  border-radius: 14px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  width: 40px;
  border-radius: 12px;
  background: #529DE1;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #50555C;
  border: 0px solid #000000;
  border-radius: 28px;
  box-shadow: 1px 1px 1px #50555C;
}
input[type=range]::-ms-fill-upper {
  background: #50555C;
  border: 0px solid #000000;
  border-radius: 28px;
  box-shadow: 1px 1px 1px #50555C;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  width: 40px;
  border-radius: 12px;
  background: #529DE1;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #50555C;
}
input[type=range]:focus::-ms-fill-upper {
  background: #50555C;
}




#durationHolocaust{
  border-radius: 50px;
  text-align: center;
  border: 0.5px solid #00284b ;
  margin-left: 10px !important;
}

.centerArea{
  display: flex;
  justify-content: center;
  align-content: center;
  
}

#areaComment{
  
  -webkit-box-shadow: 0px 7px 41px -17px rgba(0,0,0,0.39);
  -moz-box-shadow: 0px 7px 41px -17px rgba(0,0,0,0.39);
  box-shadow: 0px 7px 41px -17px rgba(0,0,0,0.39);

  border: 0.5px solid #00284b ;
}